<template>
  <div class="mt-3">
    <v-card flat>
      <v-card-text class="pt-0">
        <v-row
          justify="end"
          align="center"
        >
          <v-col
            cols="auto"
            sm="6"
          >
            <v-text-field
              v-model="table.search"
              clearable
              append-icon="mdi-magnify"
              class="input-max-width ml-auto"
              label="Search"
              hide-details
              single-line
            />
          </v-col>
        </v-row>
        <v-row class="fill-height">
          <v-col
            class="pt-0"
          >
            <v-skeleton-loader
              v-if="loading"
              type="table"
            />
            <v-data-table
              v-else
              item-key="jobNumber"
              :headers="computedHeaders"
              :items="items"
              :search.sync="table.search"
              :custom-filter="tableSearch"
              :custom-sort="tableSort"
              :expanded="table.expanded"
              :show-select="selectable"
              single-select
              :sort-by="['orderDate']"
              sort-desc
              @item-selected="(val) => $emit('item-selected', val)"
              @click:row="toggleExpandRow"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  class="px-0"
                >
                  <CampaignDashboardItem
                    :campaign-id="item.campaignId"
                    hide-target-data
                  />
                </td>
              </template>
              <template v-slot:item.statusDisplay="{ item }">
                <v-chip
                  :color="$_dashMixin_taStatusColor(item.status)"
                  light
                >
                  {{ item.statusDisplay }}
                </v-chip>
              </template>
              <template v-slot:item.trueCount="{ item }">
                <v-tooltip
                  v-if="!item.nthRecordSubCount"
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-chip
                      :color="$_dashMixin_countColor(item.count)"
                      dark
                      v-on="on"
                    >
                      {{ item.count | commaNumber }}
                    </v-chip>
                  </template>
                  <span>{{ $_dashMixin_countSendDaysLabel(item.count) }}</span>
                </v-tooltip>
                <v-tooltip
                  v-else
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-chip
                      :color="$_dashMixin_countColor(item.nthRecordSubCount)"
                      dark
                      v-on="on"
                    >
                      {{ item.nthRecordSubCount | commaNumber }}/{{ item.count | commaNumber }}
                    </v-chip>
                  </template>
                  <span>{{ item.nthRecordSubCount | commaNumber }} Nth record of {{ item.count | commaNumber }} count. {{ $_dashMixin_countSendDaysLabel(item.nthRecordSubCount) }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.orderDate="{ item }">
                {{ item.orderDate | displayDateFormat }}
              </template>
              <template v-slot:item.fileType="{ item }">
                <v-chip
                  :color="$_dashMixin_fileTypeColor(item.fileType)"
                  :dark="$_dashMixin_fileTypeColorDark(item.fileType)"
                >
                  {{ item.fileType }}
                </v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <div>
                  <v-tooltip
                    v-if="showTAActions"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        class="mr-2"
                        :color="item.status === $_taStatus_Draft ? 'success' : 'info'"
                        @click="$_dashMixin_nowwEditLink(item)"
                        v-on="on"
                      >
                        mdi-{{ item.status === $_taStatus_Draft ? 'pencil' : 'bullseye-arrow' }}
                      </v-icon>
                    </template>
                    <span>{{ item.status === $_taStatus_Draft ? 'Edit' : 'View' }} Target Audience</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="showTAActions"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        class="mr-2"
                        color="info"
                        @click="$_dashMixin_nowwCopyLink(item)"
                        v-on="on"
                      >
                        mdi-content-copy
                      </v-icon>
                    </template>
                    <span>Copy Target Audience</span>
                  </v-tooltip>
                  <v-menu
                    v-if="showTAActions && item.fileType === 'CSTM' && item.campaignId === null"
                    right
                    x-offset
                    origin="right"
                    transition="slide-x-transition"
                  >
                    <template v-slot:activator="{ on: onMenu }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on: onTooltip }">
                          <v-icon
                            :disabled="item.campaignId !== null"
                            class="mr-2"
                            color="error"
                            v-on="{ ...onMenu, ...onTooltip }"
                          >
                            mdi-close
                          </v-icon>
                        </template>
                        <span>Delete Target Audience</span>
                      </v-tooltip>
                    </template>
                    <v-card>
                      <v-card-text class="pa-5">
                        <span>Are you sure you want to delete this Target Audience?</span>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="tertiary"
                          :disabled="isLoading()"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="error"
                          :loading="isLoading()"
                          @click="$_dashMixin_deleteTA(item);"
                        >
                          Yes
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                  <v-tooltip
                    v-if="item.campaignId"
                    top
                  >
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-icon
                        style="cursor: pointer;"
                        v-on="{ ...onTooltip }"
                      >
                        mdi-chevron-{{ item._expandedIcon }}
                      </v-icon>
                    </template>
                    <span>See Campaign details</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import { dashboardMixin } from '@/shared/mixins/mailing'
import { enumsMixin } from '@/shared/mixins/general'
import { mapGetters } from 'vuex'
import claims from '@/shared/models/auth/claims'

export default {
  name: 'TATable',

  components: {
    CampaignDashboardItem: () => import('@/views/pages/components/mailing/CampaignDashboardItem')
  },

  mixins: [dashboardMixin, enumsMixin],

  props: {
    items: {
      type: Array,
      default: () => ([]),
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    noActions: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    showNumberOfResends: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    table: {
      search: undefined,
      expanded: [],
      headers: [
        {
          text: 'Job',
          value: 'jobNumber'
        },
        {
          text: 'PO #',
          value: 'poNumber'
        },
        {
          text: 'Contact',
          value: 'contactName'
        },
        {
          text: 'Status',
          value: 'statusDisplay'
        },
        {
          text: 'Order Date',
          value: 'orderDate'
        },
        {
          text: 'Order Type',
          value: 'orderType'
        },
        {
          text: 'Resends',
          value: 'numberOfResends'
        },
        {
          text: 'File',
          value: 'fileType'
        },
        {
          text: 'Count',
          value: 'trueCount'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          filterable: false
        },
        {
          text: 'Noww Contact Id',
          value: 'nowwContactId',
          hidden: true
        },
        {
          text: 'Noww Customer Id',
          value: 'nowwCustomerId',
          hidden: true
        }
      ]
    }
  }),

  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('auth', ['hasClaimKV']),
    showTAActions () {
      return this.hasClaimKV(claims.ITA_DSC)
    },
    customerHeaders () {
      return this.table.headers.filter(x => x.value !== 'accountName')
    },
    computedHeaders () {
      var headers = this.$store.getters['isViewAllCustomers']()
        ? this.table.headers.filter(x => !x.hidden)
        : this.customerHeaders.filter(x => !x.hidden)
      if (this.noActions) {
        headers = headers.filter(x => x.value !== 'actions')
      }
      if (!this.showNumberOfResends) {
        headers = headers.filter(x => x.value !== 'numberOfResends')
      }
      return headers
    }
  },

  methods: {
    tableSearch (value, search, item) {
      if (search === null) return true
      if (value === null) return false
      if (value.toString().toLowerCase().includes(search.toLowerCase())) return true
      if (moment.isMoment(value) && this.$options.filters.displayDateFormat(value).includes(search)) return true
      return false
    },
    tableSort (items, index, isDescending) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
        return items
      }
      items.sort((a, b) => {
        if (!isDescending[0]) {
          return a[index[0]] > b[index[0]] ? 1 : -1
        } else {
          return b[index[0]] > a[index[0]] ? 1 : -1
        }
      })
      return items
    },
    toggleExpandRow (value) {
      if (!value.campaignId) return
      if (this.table.expanded.includes(value)) {
        value._expandedIcon = 'down'
        this.table.expanded.splice(this.table.expanded.indexOf(value), 1)
      } else {
        value._expandedIcon = 'up'
        this.table.expanded.push(value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table.job-details.theme--light {
  background-color: #fafafa;
}
.v-data-table.job-details.theme--dark {
  background-color: #424242;
}
.v-data-table.job-details {
  border-left-width: 5px;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
  border-left-color: #ffc107;
  border-left-style: solid;
}

.v-progress-linear {
  display: inline-block;
  vertical-align: middle;
}
</style>
