var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-3" },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pt-0" },
            [
              _c(
                "v-row",
                { attrs: { justify: "end", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto", sm: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "input-max-width ml-auto",
                        attrs: {
                          clearable: "",
                          "append-icon": "mdi-magnify",
                          label: "Search",
                          "hide-details": "",
                          "single-line": ""
                        },
                        model: {
                          value: _vm.table.search,
                          callback: function($$v) {
                            _vm.$set(_vm.table, "search", $$v)
                          },
                          expression: "table.search"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "fill-height" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0" },
                    [
                      _vm.loading
                        ? _c("v-skeleton-loader", { attrs: { type: "table" } })
                        : _c("v-data-table", {
                            attrs: {
                              "item-key": "jobNumber",
                              headers: _vm.computedHeaders,
                              items: _vm.items,
                              search: _vm.table.search,
                              "custom-filter": _vm.tableSearch,
                              "custom-sort": _vm.tableSort,
                              expanded: _vm.table.expanded,
                              "show-select": _vm.selectable,
                              "single-select": "",
                              "sort-by": ["orderDate"],
                              "sort-desc": ""
                            },
                            on: {
                              "update:search": function($event) {
                                return _vm.$set(_vm.table, "search", $event)
                              },
                              "item-selected": function(val) {
                                return _vm.$emit("item-selected", val)
                              },
                              "click:row": _vm.toggleExpandRow
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "expanded-item",
                                fn: function(ref) {
                                  var headers = ref.headers
                                  var item = ref.item
                                  return [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "px-0",
                                        attrs: { colspan: headers.length }
                                      },
                                      [
                                        _c("CampaignDashboardItem", {
                                          attrs: {
                                            "campaign-id": item.campaignId,
                                            "hide-target-data": ""
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.statusDisplay",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "v-chip",
                                      {
                                        attrs: {
                                          color: _vm.$_dashMixin_taStatusColor(
                                            item.status
                                          ),
                                          light: ""
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.statusDisplay) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.trueCount",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    !item.nthRecordSubCount
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-chip",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              color: _vm.$_dashMixin_countColor(
                                                                item.count
                                                              ),
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "commaNumber"
                                                                )(item.count)
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$_dashMixin_countSendDaysLabel(
                                                    item.count
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      : _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-chip",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              color: _vm.$_dashMixin_countColor(
                                                                item.nthRecordSubCount
                                                              ),
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "commaNumber"
                                                                )(
                                                                  item.nthRecordSubCount
                                                                )
                                                              ) +
                                                              "/" +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "commaNumber"
                                                                )(item.count)
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("commaNumber")(
                                                    item.nthRecordSubCount
                                                  )
                                                ) +
                                                  " Nth record of " +
                                                  _vm._s(
                                                    _vm._f("commaNumber")(
                                                      item.count
                                                    )
                                                  ) +
                                                  " count. " +
                                                  _vm._s(
                                                    _vm.$_dashMixin_countSendDaysLabel(
                                                      item.nthRecordSubCount
                                                    )
                                                  )
                                              )
                                            ])
                                          ]
                                        )
                                  ]
                                }
                              },
                              {
                                key: "item.orderDate",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("displayDateFormat")(
                                            item.orderDate
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.fileType",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "v-chip",
                                      {
                                        attrs: {
                                          color: _vm.$_dashMixin_fileTypeColor(
                                            item.fileType
                                          ),
                                          dark: _vm.$_dashMixin_fileTypeColorDark(
                                            item.fileType
                                          )
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.fileType) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.actions",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _vm.showTAActions
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  color:
                                                                    item.status ===
                                                                    _vm.$_taStatus_Draft
                                                                      ? "success"
                                                                      : "info"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$_dashMixin_nowwEditLink(
                                                                      item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                      mdi-" +
                                                                  _vm._s(
                                                                    item.status ===
                                                                      _vm.$_taStatus_Draft
                                                                      ? "pencil"
                                                                      : "bullseye-arrow"
                                                                  ) +
                                                                  "\n                    "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.status ===
                                                        _vm.$_taStatus_Draft
                                                        ? "Edit"
                                                        : "View"
                                                    ) + " Target Audience"
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.showTAActions
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  color: "info"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$_dashMixin_nowwCopyLink(
                                                                      item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                      mdi-content-copy\n                    "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Copy Target Audience")
                                                ])
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.showTAActions &&
                                        item.fileType === "CSTM" &&
                                        item.campaignId === null
                                          ? _c(
                                              "v-menu",
                                              {
                                                attrs: {
                                                  right: "",
                                                  "x-offset": "",
                                                  origin: "right",
                                                  transition:
                                                    "slide-x-transition"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var onMenu = ref.on
                                                        return [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var onTooltip =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "mr-2",
                                                                              attrs: {
                                                                                disabled:
                                                                                  item.campaignId !==
                                                                                  null,
                                                                                color:
                                                                                  "error"
                                                                              }
                                                                            },
                                                                            Object.assign(
                                                                              {},
                                                                              onMenu,
                                                                              onTooltip
                                                                            )
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              "\n                          mdi-close\n                        "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Delete Target Audience"
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c(
                                                  "v-card",
                                                  [
                                                    _c(
                                                      "v-card-text",
                                                      { staticClass: "pa-5" },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Are you sure you want to delete this Target Audience?"
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _c("v-divider"),
                                                    _c(
                                                      "v-card-actions",
                                                      [
                                                        _c("v-spacer"),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              color: "tertiary",
                                                              disabled: _vm.isLoading()
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        Cancel\n                      "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              color: "error",
                                                              loading: _vm.isLoading()
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.$_dashMixin_deleteTA(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        Yes\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        item.campaignId
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var onTooltip = ref.on
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticStyle: {
                                                                  cursor:
                                                                    "pointer"
                                                                }
                                                              },
                                                              Object.assign(
                                                                {},
                                                                onTooltip
                                                              )
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                      mdi-chevron-" +
                                                                  _vm._s(
                                                                    item._expandedIcon
                                                                  ) +
                                                                  "\n                    "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("See Campaign details")
                                                ])
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }